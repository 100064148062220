import { OActionDelete as ActionDelete, OActionCancel as ActionCancel} from 'o365-data-components';
import { $t, logger, utils } from 'o365-utils';
import { OTextEditor, ONumberEditor, OBitEditor, ODateEditor, OContentEditable, OCheck, OLink } from 'o365-ui-components';
import { Comment, resolveComponent, ref, h, withModifiers } from 'vue';
import { useErrorCapture } from 'o365-vue-utils';
import { OColContainer } from 'o365-ui-components';
import { useAsyncComponent } from 'o365-vue-utils';
import {getDataObjectById} from 'o365-dataobject';
// import { userSession } from 'o365.modules.configs.ts';
const Components = { OTextEditor, ONumberEditor, OBitEditor, ODateEditor };
const Renderers = { OCheck, OLink };

const RowErrorDropdown = useAsyncComponent('./components.ErrorDropdown.vue', { importFn: () => import('./components.ErrorDropdown.vue')});
/**
 * @typedef {import('o365.modules.DataObject.Types.ts').DataItemModel} DataItemModel
 * @typedef {import('o365.controls.DataGrid.Column.ts').default} DataColumn
 */

function ExpandableCellRenderer(props) {
    function handleOnClick() {
        if (props.row.o_loading) { return; }
        if (props.column.cellRendererParams?.handleClick) {
            props.column.cellRendererParams.handleClick(props.row, props.column);
        }
    }

    const marginLeft = props.column.cellRendererParams.getLeftMargin(props.row, props.column);
    const expandable = props.column.cellRendererParams.isExpandable(props.row, props.column);
    let collapsed;
    if (expandable) {
        collapsed = props.column.cellRendererParams.isCollapsed(props.row, props.column);
    }
    const display = props.column.cellRendererParams.getDisplay(props.row, props.column);
    const loading = props.column.cellRendererParams.isLoading(props.row, props.column);
    const loadingStyle = 'border-width: var(--bs-spinner-border-width); border-style: solid; border-color: currentColor; border-right-color: transparent;';

    if (props.row.o_groupLoadNextPage) {
        window.setTimeout(() => {
            props.row.o_groupLoadNextPage(props.row);
        }, 1);
    }

    const prefix = props.column.cellRendererParams.hasPrefix
        ? props.column.cellRendererParams.getPrefix(props.row)
        : null;

    const boldDisplay = expandable && (props.column.cellRendererParams.boldDisplay ?? false);

    const DisplayTag = boldDisplay ? 'b' : 'span';

    return <span
        style={{ 'margin-left': marginLeft + 'px' }}>
        {expandable
            ? <>
                <span class="o365-group-expand me-1 p-2" role="button" onClick={handleOnClick}>
                    {
                        loading
                            ? <span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true" style={loadingStyle}></span>
                            : collapsed
                                ? <i class="bi bi-plus-square"></i>
                                : <i class="bi bi-dash-square"></i>
                    }
                </span>
                {
                    prefix
                        ? <span>{prefix}<DisplayTag>{display}</DisplayTag></span>
                        : <DisplayTag>{display}</DisplayTag>
                }
            </>
            : <>
                <span class="o365-group-expand me-1 p-2">
                    <i class="bi bi-square" style="color: transparent;"></i>
                </span>
                {
                    prefix
                        ? <span>{prefix}<DisplayTag>{display}</DisplayTag></span>
                        : <DisplayTag>{display}</DisplayTag>
                }
            </>
        }
    </span>;
}
ExpandableCellRenderer.props = ['row', 'column'];

const CellRenderer = {
    name: 'CellRenderer',
    props: ['modelValue', 'column', 'editModeOn', 'rowIndex'],
    emits: ['update:modelValue'],
    setup(props, context) {

        const renderError = () => h('span', {
            class: 'text-danger',
            title: 'An error has occurred when trying to render this cell',
            onClick: () => { capturedError.value = null; }
        }, [
            h('i', { class: 'bi bi-exclamation-triangle-fill me-1' }),
            'Render Error'
        ]);;

        const [capturedError] = useErrorCapture({
            consoleMessagee: `Error encountered when trying to render column: ${props.column?.colId}`
        });

        const renderWithErrorBoundry = (renderFn, props) => {
            try {
                return renderFn(props);
            } catch (ex) {
                logger.error(`Error encountered when trying to render column: ${props.column?.colId}\n`, ex);
                return renderError();
            }
        }

        return () => {
            return capturedError.value
                ? renderError()
                : renderWithErrorBoundry(props.column.cellRenderSlot, {
                    'row': props.modelValue,
                    'onUpdate:row': (value) => context.emit('update:modelValue', value),
                    'column': props.column,
                    'editModeOn': props.editModeOn,
                    'rowIndex': props.rowIndex
                });
        }
    }
};

function ODataTableBodyCell(props) {
    function columnCellRenderer() {
        if (props.col.cellRenderSlot) {
            return <CellRenderer column={props.col} v-model={props.row.item} rowIndex={props.row.index} />
        } else if (props.col.cellRenderer) {
            if (props.col.cellRendererIsFunction) {
                return props.col.cellRenderer(props.row);
            } else {
                let renderer = props.col.cellRenderer
                if (typeof renderer === 'string') {
                    renderer = { ...Renderers }[renderer] ?? resolveComponent(renderer);
                }
                const rowProp = renderer.props.includes('row') ? props.row.item : null;
                return <renderer
                    v-model={props.row.item}
                    column={props.col}
                    editModeOn={props.isActiveEditCell}
                    row={rowProp}
                />
            }
        } else {
            return utils.format(props.row.item[props.col.field], props.col);
        }
    }

    function renderColumn() {
        if (props.col.field === 'o365_Action') {
            return ((props.row.item.current && !props.row.item.isDirty && !props.row.item.isError) || props.row.item.isDeleting)
                ? <ActionDelete row={props.row.item} confirm={props.col.cellRendererParams?.deleteConfirm} softDelete={props.col.cellRendererParams?.softDelete}>
                    <i class="bi bi-x-lg"></i>
                </ActionDelete>
                : (props.row.item.current && (props.row.item.isDirty || props.row.item.hasChanges) && !props.row.item.error)
                    ? <ActionCancel row={props.row.item}>
                        <i class="bi bi-arrow-counterclockwise"></i>
                    </ActionCancel>
                    : '';
        } else {
            // return !isEditable() ? columnCellRenderer() : renderEditor();
            return !props.isActiveEditCell ? columnCellRenderer() : renderEditor();
        }
    }

    function renderEditor() {
        let editor;
        let isSlot = false;
        if (props.col.cellEditorSlot) {
            editor = props.col.cellEditorSlot;
            isSlot = true;
        } else {
            if (typeof props.col.cellEditor === 'string') {
                editor = { OContentEditable, ...Components }[props.col.cellEditor] ?? resolveComponent(props.col.cellEditor);
            } else {
                editor = props.col.cellEditor;
            }
        }

        if (!editor || typeof editor === 'string') {
            return h(Comment, `${editor} - failed to resolve`);
        }

        return isSlot
            ? <editor
                modelValue={props.row.item}
                row={props.row.item}
                column={props.col}
                ref='editorRef'
                {...(props.col.cellEditorParams ?? {})} />
            : <editor
                modelValue={props.row.item[props.col.field]}
                onUpdate:modelValue={(value) => {
                    if (value === null && typeof props.row.item[props.col.field] === 'undefined') { return; }
                    props.row.item[props.col.field] = value;
                }}
                row={(editor.props?.includes && editor.props.includes('row')) ? props.row.item : null}
                column={(editor.props?.includes && editor.props.includes('column')) ? props.col : null}
                ref="editorRef"
                {...(props.col.cellEditorParams ?? {})} />
    }

    const isEditable = () => {
        if (typeof props.col.editable === 'function') {
            return props.col.editable(props.row.item);
        } else {
            return props.col.editable;
        }
    }

    const className = 'o365-body-cell' + (isEditable() ? ' o365-editable-cell' : '');

    return <div class={className} tabindex="-1" data-o365-colindex={props.colIndex} o365-field={props.col.colId}
        class={props.col.cellClass}
        style={[{ 'min-width': props.col.width + props.col.widthAdjustment + 'px', 'max-width': props.col.width + props.col.widthAdjustment + 'px' }, { 'left': props.col.left + 'px' }, ...props.col.cellStyles]}>
            {renderColumn()}
    </div>;
}
ODataTableBodyCell.props = {
    col: Object,
    colIndex: Number,
    isActiveEditCell: Boolean,
    row: Object
};

/**
 * @param {DataGridBodyCellProps} props
 */
function ODataGridBodyCell(props, ctx) {
    function columnCellRenderer() {
        if (props.row.item?.o_groupHeaderRow && props.col.aggregateSlot && typeof props.col.aggregateSlot === 'function') {
            return props.col.aggregateSlot({ row: props.row.item, isAggregated: props.col._aggregateEnabled});
        } else if (props.col.cellRenderSlot) {
            return <CellRenderer column={props.col} v-model={props.row.item} rowIndex={props.row.index} />;
        } else if (props.col.cellRenderer) {
            if (props.col.cellRendererIsFunction) {
                return props.col.cellRenderer(props.row);
            } else {
                let renderer = props.col.cellRenderer
                if (typeof renderer === 'string') {
                    renderer = { ...Renderers }[renderer] ?? resolveComponent(renderer);
                }
                const rowProp = renderer.props.includes('row') ? props.row.item : null;
                const cellRendererParams = props.col.cellRendererParams && typeof props.col.cellRendererParams == 'object'
                    ? props.col.cellRendererParams
                    : {};
                return <renderer
                    v-model={props.row.item}
                    column={props.col}
                    editModeOn={props.isActiveEditCell}
                    disabled={props.disabled}
                    row={rowProp}
                    {...cellRendererParams}
                />
            }
        } else {
            return utils.format(props.row.item[props.col.field], props.col);
        }
    }

    function renderEditor() {
        let editor;
        let isSlot = false;
        if (props.col.cellEditorSlot) {
            editor = props.col.cellEditorSlot;
            isSlot = true;
        } else {
            if (typeof props.col.cellEditor === 'string') {
                editor = { OContentEditable, ...Components }[props.col.cellEditor] ?? resolveComponent(props.col.cellEditor);
            } else {
                editor = props.col.cellEditor;
            }
        }

        if (!editor || typeof editor === 'string') {
            return h(Comment, `${editor} - failed to resolve`);
        }

        return isSlot
            ? <editor
                modelValue={props.row.item}
                row={props.row.item}
                column={props.col}
                ref='editorRef'
                {...(props.col.cellEditorParams ?? {})} />
            : <editor
                modelValue={props.row.item[props.col.field]}
                onUpdate:modelValue={(value) => {
                    if (value === null && typeof props.row.item[props.col.field] === 'undefined') { return; }
                    props.row.item[props.col.field] = value;
                }}
                row={editor.props.includes('row') ? props.row.item : null}
                column={editor.props.includes('column') ? props.col : null}
                ref="editorRef"
                {...(props.col.cellEditorParams ?? {})} />
    }

    function renderColumn() {
        switch (props.col.field) {
            case 'o365_System':
       
                if (props.row.item.error) {
                    return <RowErrorDropdown row={props.row.item}></RowErrorDropdown>
                } else if (props.row.item.isSaving) {
                    return <div class="spinner-border spinner-border-sm mt-1" role="status"></div>
                } else if (props.row.item.hasChanges && props.row.item.current) {
                    if (props.row.item.isNewRecord && props.row.item.disableSaving) {
                        const ActionCommitSave = useAsyncComponent('components.PasteCommitDropdown.vue', { importFn: () => import('./components.PasteCommitDropdown.vue')});
                        return <ActionCommitSave row={props.row.item} type="save"></ActionCommitSave>
                    }
                    const saveRow = (e) => {
                        e.stopPropagation();
                        if (props.row.item.disableSaving) {
                            props.row.item.disableSaving = false;
                        }
                        props.row.item.save()
                    };
                    // return <i class="bi bi-save text-primary" style="cursor: pointer;" title={$t('Save')} onClick={saveRow} ></i>
                    return <i class="bi bi-check-lg icon-bold text-primary" style="cursor: pointer;" title={$t('Save')} onClick={saveRow} ></i>
                } else if (props.row.item.isNewRecord && props.row.item.isEmpty) {
                    const batchFocus = (e) => {
                        const target = e.target;
                        e.target.classList.add('text-primary');
                        window.addEventListener('click', () => {
                            target.classList.remove('text-primary');
                        }, true);
                    };
                    return <i class="bi bi-asterisk" onClick={batchFocus} ></i>
                } else if (props.row.item.isNewRecord && props.row.item.current) {
                    const saveRow = (e) => { e.stopPropagation(); props.row.item.save() };
                    return <i class="bi bi-asterisk" onClick={saveRow} ></i>
                } else if (props.row.item.current) {
                    if (props.features && props.features.includes('rowdrag')) {
                        return <i class="bi bi-grip-vertical o365-rowhandle" style="opacity:1" draggable="true" data-o365-drag-index={props.row.item?.index}
                            data-o365-drag-key={props.row.item?.key} ></i>;
                    }
                    return <i class="bi bi-caret-right-fill"></i>
                } else {
                    if (props.features && props.features.includes('rowdrag')) {
                        return <i class="bi bi-grip-vertical o365-rowhandle" draggable="true" data-o365-drag-index={props.row.item?.index}
                            data-o365-drag-key={props.row.item?.key} ></i>;
                    }
                    return '';
                }
       
            case 'o365_MultiSelect':
                if (!props.row.item.o_groupHeaderRow) {
                    return <input type="checkbox" class="form-check-input p-2" disabled={props.row.item.isNewRecord} 
                        onClick={withModifiers((pEvent) => {
                            if (props.handleSelection) {
                                props.handleSelection(pEvent);
                            }
                        // data ? dataGridControl.selectionControl.onSelection(row) : null
                        // @click.passive="e => dataGridControl.handleScrollItemMultiSelect(e, row)"
                    }, ['stop'])} checked={props.row.item.isSelected} indeterminate={!props.row.item.isSelected && props.row.item.hasSelected}></input>
                } else {
                    return '';
                }
            case 'o365_Action':
                if (props.row.item.o_groupHeaderRow) { return ''; }
                if (!props.row.item.current) {
                    return '';
                } else if (props.row.item.isNewRecord) {
                    if (props.row.item.disableSaving) {
                        const ActionCommitSave = useAsyncComponent('components.PasteCommitDropdown.vue', { importFn: () => import('./components.PasteCommitDropdown.vue') });
                        return <ActionCommitSave row={props.row.item} type="cancel"></ActionCommitSave>
                    }
                    const removeItem = () => {
                        import('o365-dataobject').then(x => {
                            const dataObject = x.getDataObjectById(props.row.item.dataObjectId, props.row.item.appId);
                            const index = props.row.item.index;
                            dataObject.batchData.removeItem(index);
                            // const storageIndex = dataObject.batchData.getInversedIndex(index);
                            // dataObject.batchData.storage.removeItem(storageIndex);

                        });
                    }
                    return <button class="btn btn-sm btn-link" onClick={removeItem} disabled={!props.row.item.hasChanges} title={$t('Remove record')}>
                        <i class="bi bi-x-lg"></i>
                    </button>
                } else if ((!props.row.item.hasChanges && !props.row.item.error) || props.row.item.isDeleting) {
                    if (props.col.cellRenderSlot) {
                        const CustomAction = props.col.cellRenderSlot;
                        return <CustomAction row="props.row.item" index="props.index"></CustomAction>
                    }
                    if(props.row.item.dataObjectId && props.row.item.appId){
                        const dataObject = getDataObjectById(props.row.item.dataObjectId, props.row.item.appId);
                        if(dataObject && !dataObject.allowDelete){
                            return <button class="btn btn-sm btn-link text-muted" style="cursor: 'default'" title={$t('Delete is not supported')}><i class="bi bi-info-lg"></i></button>
                        }
                    }
                    return <ActionDelete row={props.row.item} confirm={props.col.cellRendererParams?.deleteConfirm} softDelete={props.col.cellRendererParams?.softDelete}>
                        <i class="bi bi-x-lg"></i>
                    </ActionDelete>
                } else if (props.row.item.hasChanges && !props.row.item.error) {
                    return <ActionCancel row={props.row.item}>
                        <i class="bi bi-arrow-counterclockwise"></i>
                    </ActionCancel>
                } else {
                    return '';
                }
            default:
                const editorSlot = ctx?.slots?.editor
                    ? ctx.slots.editor
                    : () => '';
                return !props.isActiveEditCell ? columnCellRenderer() : editorSlot();
        }
    }

    const renderRequired = () => {
        return !props.isTable && props.col.required && props.col.editable && props.row.item.isNewRecord
            ? <i class="o365-required-cell-icon"></i>
            : undefined;
    }

    const title = (typeof props.col.cellTitle === 'function')
        ? props.col.cellTitle(props.row.item)
        : props.col.cellTitle;

    const isEditable = typeof props.col.editable === 'function' ? props.col.editable(props.row.item) : props.col.editable;
    let className = (props.selectionClass ? `o365-body-cell ${props.selectionClass}` : 'o365-body-cell') + ((isEditable && !props.isActiveEditCell) ? ' o365-body-cell-editable' : '');
    if (isEditable && props.isActiveEditCell) {
        className += ' o365-editor-cell';
    }

    let userStyles = [];
    if (typeof props.col.cellStyle === 'function') {
        const res = props.col.cellStyle(props.row?.item);
        switch (typeof res) {
            case 'string':
                userStyles = [res];
                break;
            case 'object':
                userStyles = res;
                break;
            default:
                userStyles = [];
                break;
        };
    } else if (typeof props.col.cellStyle != null) {
        userStyles = [props.col.cellStyle];
    }

    // if ([96470].includes(userSession.personId)) {
    //     let debugStyle = `background: rgba(${Math.floor(Math.random() * 255 + 1)}, ${Math.floor(Math.random() * 255 + 1)}, ${Math.floor(Math.random() * 255 + 1)}, 0.2)`
    //     userStyles.push(debugStyle);
    // }

    let userClass = [];
    if (typeof props.col.classFn === 'function') {
        const res = props.col.classFn(props.row?.item);
        switch (typeof res) {
            case 'string':
                userClass = [res];
                break;
            case 'object':
                userClass = res;
                break;
            default:
                userClass = [];
                break;
        };
    }
    const isAutoHeight = props.col.autoHeight;

    try {
        if (isAutoHeight) {
            const autoHeightCell = props.col.autoHeightApi.getCellComponent();
            return <div tabindex="-1" data-o365-colindex={props.colIndex} o365-field={props.col.colId} title={title}
                class={[className, props.col.cellClass, ...userClass]}
                style={[
                    { 'width': props.col.width + props.col.widthAdjustment + 'px' },
                    { 'left': props.col.left + 'px' },
                    ...props.col.cellStyles, ...userStyles]}>
                {
                    <autoHeightCell column={props.col} row={props.row}>
                        {renderColumn()}
                        {renderRequired()}
                    </autoHeightCell>
                }
            </div>;
            // return <autoHeightCell column={props.col} row={props.row}>
            //     {{
            //         default: ctx => <div tabindex="-1" data-o365-colindex={props.colIndex} o365-field={props.col.colId} title={title}
            //             class={[className, 'text-wrap', props.col.cellClass, ...userClass]} ref={ctx.target} data-o365-autoheight-index={props.row.index}
            //             style={[{ 'width': props.col.width + props.col.widthAdjustment + 'px' }, { 'left': props.col.left + 'px' }, ...props.col.cellStyles, ...userStyles]}>
            //             {renderColumn()}
            //             {renderRequired()}
            //         </div>
            //     }}
            // </autoHeightCell>
        } 
        else {
            return <div tabindex="-1" data-o365-colindex={props.colIndex} o365-field={props.col.colId} title={title}
                class={[className, props.col.cellClass, ...userClass]}
                style={[
                    { 'width': props.col.width + props.col.widthAdjustment + 'px' },
                    { 'left': props.col.left + 'px' },
                    ...props.col.cellStyles, ...userStyles]}>
                {renderColumn()}
                {renderRequired()}
            </div>;
        }
    } catch (ex) {
        logger.error(ex);
        return <div class={className} tabindex="-1" data-o365-colindex={props.colIndex} o365-field={props.col?.colId} title="An error has occured when rendering this cell"
            class={[props.col?.cellClass, ...userClass, 'text-danger']}
            style={[{ 'width': props.col.width + props.col.widthAdjustment + 'px' }, { 'left': props.col.left + 'px' }, ...props.col.cellStyles, ...userStyles]}>
            <i class="bi bi-exclamation-triangle-fill me-1"></i>
            {'Render Error'}
        </div>
    }
}
/**
 * @typedef {{
 *  col: DataColumn,
 *  colIndex: number,
 *  isActiveEditCell: boolean
 *  row: {
 *      item: DataItemModel,
 *      pos: number,
 *      index: number
 *  },
 *  selectionClass: string,
 *  handleSelection: (pEvent: MouseEvent) => any
 *  disabled: boolean
 *  isTable?: boolean
 * }} DataGridBodyCellProps
 */
ODataGridBodyCell.props = {
    col: Object,
    colIndex: Number,
    isActiveEditCell: Boolean,
    row: Object,
    selectionClass: String,
    disabled: Boolean,
    handleSelection: Function,
    features: Array,
    isTable: Boolean
};

function BodyWrapper(props, { slots }) {
    const Container = props.is ?? OColContainer;
    return props.disabled
        ? slots.default()
        : <Container class={props.class} style={props.style}>
            {slots.default()}
        </Container>;
}
BodyWrapper.props = { disabled: Boolean, class: null, style: null, is: String };

/**
 * Async component loader
 */
const AsyncLoader = {
    name: 'AsyncLoader',
    props: {
        dataObject: Object,
        component: {
            type: String,
            default: 'o365.vue.components.TreeifyLevels.vue'
        }
    },
    data() {
        return {
            isLoaded: false,
        };
    },
    created() {
        import(this.component).then(module => {
            this._TreeLevels = module.default;
            this.isLoaded = true;
        });
    },
    render() {
        return this.isLoaded
            ? <this._TreeLevels class="position-absolute" style="right: 32px;" data-object={this.dataObject}></this._TreeLevels>
            : <div class="spinner-border spinner-border-sm float-end" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>;
    }
};

/**
 * Funciton that returns TreeColumnHeader functinoal component in an async retriever
 */
function TreeColumnHeaderFactory(dataGridControl, componentToLoad) {
    function TreeColumnHeader(props) {
        return <>
            <span class="o365-header-cell-text text-truncate">
                {props.column.headerName}
            </span>
            <AsyncLoader component={componentToLoad} data-object={dataGridControl?.value?.dataObject ?? dataGridControl?.dataObject}></AsyncLoader>
        </>;
    }
    TreeColumnHeader.props = ['column'];

    return TreeColumnHeader;
}

export { ODataGridBodyCell, BodyWrapper, ExpandableCellRenderer, TreeColumnHeaderFactory, ODataTableBodyCell }


